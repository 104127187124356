<script setup>
import { nextTick } from 'vue'
import ApplicationLogo from '@/Components/ApplicationLogo.vue'
import GuestLayout from '@/Layouts/GuestLayout.vue'
import InputError from '@/Components/InputError.vue'
import { Head, Link, useForm } from '@inertiajs/vue3'
import { LockOutlined, UserOutlined } from '@ant-design/icons-vue'
import { trans } from 'laravel-vue-i18n'

defineProps({
  canResetPassword: Boolean,
  status: String
})

const form = useForm({
  email: '',
  password: '',
  remember: false
})

const submit = () => {
  form.post(route('login'), {
    onFinish: () => form.reset('password')
  })
}

const setDemoCredentials = () => {
  form.email = 'admin@opmation.com'
  form.password = '12345678'

  nextTick(() => submit()) // Ensures form updates before submission
}
</script>

<template>
  <GuestLayout>
    <Head :title="trans('Log in')" />

    <div class="mb-4">
      <span class="text-gray-500 dark:text-gray-200">{{ trans('Sign in to') }}</span>
      <h3 class="text-2xl font-bold text-slate-600 dark:text-white">
        {{ trans('Access the panel') }}
      </h3>
    </div>

    <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
      {{ status }}
    </div>

    <a-form layout="vertical" @submit.prevent="submit">
      <a-form-item
        :class="[
          form.errors.email
            ? 'ant-form-item-has-feedback ant-form-item-has-error ant-form-item-with-help'
            : ''
        ]"
      >
        <a-input
          class="p-2"
          id="email"
          type="email"
          v-model:value="form.email"
          autofocus
          autocomplete="email"
          :placeholder="trans('Email')"
        >
          <template #prefix>
            <UserOutlined class="opacity-30" />
          </template>
        </a-input>

        <InputError :message="form.errors.email" />
      </a-form-item>

      <a-form-item
        :class="[
          form.errors.password
            ? 'ant-form-item-has-feedback ant-form-item-has-error ant-form-item-with-help'
            : ''
        ]"
      >
        <a-input
          class="p-2"
          id="password"
          type="password"
          v-model:value="form.password"
          autocomplete="current-password"
          :placeholder="trans('Password')"
        >
          <template #prefix>
            <LockOutlined class="opacity-30" />
          </template>
        </a-input>
        <InputError :message="form.errors.password" />
      </a-form-item>

      <div class="flex items-center justify-between mb-3">
        <a-checkbox name="remember" v-model:checked="form.remember">{{
          trans('Remember me')
        }}</a-checkbox>
        <Link :href="route('password.request')" prefetch>
          <a-button type="link" v-if="canResetPassword">
            {{ trans('Forgot your password?') }}
          </a-button>
        </Link>
      </div>

      <a-button
        :disabled="form.processing"
        htmlType="submit"
        type="primary"
        class="w-full h-[40px]"
      >
        {{ trans('Log in') }}
      </a-button>

      <div class="mt-5 text-center" v-demo>
        <div class="p-4 border rounded-sm bg-slate-100 text-sm mb-3">
          <p>
            <strong>{{ trans('Email') }}:</strong> admin@opmation.com
          </p>
          <p>
            <strong>{{ trans('Password') }}:</strong> 12345678
          </p>
        </div>
        <a-button type="default" @click="setDemoCredentials" class="bg-gray-100">
          {{ trans('Use Admin Credentials') }}
        </a-button>
      </div>
    </a-form>
  </GuestLayout>
</template>
